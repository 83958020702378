import { defineAsyncComponent } from 'vue';
<template>
    <div class="contenedor-general">
        <div class="contenedor-grafica">
            <Grafica
                :hombres="dataGrafica.hombres"
                :mujeres="dataGrafica.mujeres"
                :pie="showGrafica.pie"
                :barras="showGrafica.barras"
                
            />
        </div>
        <div class="contenedor-descripcion">
            <p>{{ dataDescripcion }}</p>
        </div>
        
    </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    props: {
        dataGrafica: {
            type: Object
        },
        dataDescripcion: {
            type: String
        },
        showGrafica: {
            type: Object
        }

    },
    components: {
        Grafica: defineAsyncComponent( () => import('../components/Grafica.vue'))
    }
}
</script>

<style lang="scss" scoped>

    .contenedor-general{
        width:100%;
        margin:20px auto;
        height:calc(100vh - 270px);
        display: flex; flex-direction:column;

        & .contenedor-grafica {
            width:80%;
            height: 800px;
            margin:10px auto;
            text-align:center;
            padding:12px 10px;
            display: flex; align-items:center; justify-content:center;
            
        }
         & .contenedor-descripcion{
            width:90%;
            height:400px;
            margin:10px auto;
            padding:12px 20px;
            text-align:center;
            font-size: 18px;
            font-weight:bold;
        }
    }
</style>